import React, { useState, useEffect, useRef } from "react";
import Button from "react-bootstrap/Button";
import config from "../../../config";
import { getIntouch, requestCallback } from "../../../api.services.js/others";
import swal from "sweetalert";
import Form from "react-bootstrap/Form";
import { FaAngleRight, FaWhatsapp, FaPhone } from "react-icons/fa";
import { gtagConversionEvent } from "../../../gtag";
import { useRouter } from "next/router";
import { Dropdown } from "react-bootstrap";
import ReactCountryFlag from "react-country-flag";
import Modal from "react-bootstrap/Modal";
import cookie from "js-cookie";
import AddressAutocomplete from "../address";
import axios from "axios";
const Footer = (props) => {
  const router = useRouter();
  const [TimePopup, setTimePopup] = useState(false);

  const [getInTouch, setGetInTouch] = useState(false);
  const [isActive, setIsActive] = useState(false);
  const [tarrekImage, settarrekImage] = useState(false);
  const [LocationPopop, setLocationPopop] = useState(false);
  const [timeOutIDS, settimeOutIDS] = useState([]);

  const [isClassToggled, setIsClassToggled] = useState(Array(15).fill(false));
  const toggleClass = (index) => {
    const updatedStates = [...isClassToggled];
    updatedStates[index] = !updatedStates[index];
    setIsClassToggled(updatedStates);
  };

  const [callBackDto, setCallBackDto] = useState({
    name: "",
    number: "",
    pincode: cookie.get("pincode"),
    error: "",
    loading: false,
    countryCode: "",
    email: "",
    cc: "",
  });
  const [touch, setTouch] = useState({
    name: "",
    number: "",
    pincode: cookie.get("pincode"),
    countryCode: "", // New property for country code
    error: "",
    loading: false,
    cc: "",
    email: "",
  });
  const [recording, setRecording] = useState(false);
  const [audioBlob, setAudioBlob] = useState(null);
  const [recordedText, setrecordedText] = useState("");

  const mediaRecorderRef = useRef(null);
  const audioChunksRef = useRef([]);

  const startRecording = async () => {
    const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
    mediaRecorderRef.current = new MediaRecorder(stream);
    mediaRecorderRef.current.ondataavailable = (event) => {
      audioChunksRef.current.push(event.data);
    };
    mediaRecorderRef.current.onstop = () => {
      const audioBlob = new Blob(audioChunksRef.current, { type: "audio/wav" });
      setAudioBlob(audioBlob);
      audioChunksRef.current = [];
    };
    mediaRecorderRef.current.start();
    setRecording(true);
    const recognition = new window.webkitSpeechRecognition();
    recognition.continuous = true;
    recognition.interimResults = false;
    recognition.lang = "en-US"; // Set language as needed

    recognition.onresult = (event) => {
      const currentTranscript =
        event.results[event.results.length - 1][0].transcript;
      setrecordedText(currentTranscript);
      console.log(currentTranscript);
    };
    recognition.onerror = (event) => {
      console.log("Speech recognition error detected: " + event.error);
    };
    recognition.onend = () => {
      console.log("Speech recognition ended.");
    };
    recognition.start();
  };

  const stopRecording = () => {
    mediaRecorderRef.current.stop();
    setRecording(false);
  };

  const uploadAudio = async (LeadID) => {
    const formData = new FormData();
    formData.append("audio", audioBlob, "recording.wav");
    formData.append("LeadID", LeadID);
    formData.append("Audio_Text", recordedText);

    try {
      const response = await axios.post(
        "https://obl-js.orientbell.com/uploadAudio",
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      console.log("Upload success", response.data);
    } catch (error) {
      console.log("Upload error", error);
    }
  };

  const countries = [
    { name: "Afghanistan", code: "93", cc: "AF" },
    { name: "Albania", code: "355", cc: "AL" },
    { name: "Algeria", code: "213", cc: "DZ" },
    { name: "American Samoa", code: "1-684", cc: "AS" },
    { name: "Andorra", code: "376", cc: "AD" },
    { name: "Angola", code: "244", cc: "AO" },
    { name: "Anguilla", code: "1-264", cc: "AI" },
    { name: "Antarctica", code: "672", cc: "AQ" },
    { name: "Antigua and Barbuda", code: "1-268", cc: "AG" },
    { name: "Argentina", code: "54", cc: "AR" },
    { name: "Armenia", code: "374", cc: "AM" },
    { name: "Aruba", code: "297", cc: "AW" },
    { name: "Australia", code: "61", cc: "AU" },
    { name: "Austria", code: "43", cc: "AT" },
    { name: "Azerbaijan", code: "994", cc: "AZ" },
    { name: "Bahamas", code: "1-242", cc: "BS" },
    { name: "Bahrain", code: "973", cc: "BH" },
    { name: "Bangladesh", code: "880", cc: "BD" },
    { name: "Barbados", code: "1-246", cc: "BB" },
    { name: "Belarus", code: "375", cc: "BY" },
    { name: "Belgium", code: "32", cc: "BE" },
    { name: "Belize", code: "501", cc: "BZ" },
    { name: "Benin", code: "229", cc: "BJ" },
    { name: "Bermuda", code: "1-441", cc: "BM" },
    { name: "Bhutan", code: "975", cc: "BT" },
    { name: "Bolivia", code: "591", cc: "BO" },
    { name: "Bosnia and Herzegovina", code: "387", cc: "BA" },
    { name: "Botswana", code: "267", cc: "BW" },
    { name: "Brazil", code: "55", cc: "BR" },
    { name: "British Indian Ocean Territory", code: "246", cc: "IO" },
    { name: "British Virgin Islands", code: "1-284", cc: "VG" },
    { name: "Brunei", code: "673", cc: "BN" },
    { name: "Bulgaria", code: "359", cc: "BG" },
    { name: "Burkina Faso", code: "226", cc: "BF" },
    { name: "Burundi", code: "257", cc: "BI" },
    { name: "Cambodia", code: "855", cc: "KH" },
    { name: "Cameroon", code: "237", cc: "CM" },
    { name: "Canada", code: "1", cc: "CA" },
    { name: "Cape Verde", code: "238", cc: "CV" },
    { name: "Cayman Islands", code: "1-345", cc: "KY" },
    { name: "Central African Republic", code: "236", cc: "CF" },
    { name: "Chad", code: "235", cc: "TD" },
    { name: "Chile", code: "56", cc: "CL" },
    { name: "China", code: "86", cc: "CN" },
    { name: "Christmas Island", code: "61", cc: "CX" },
    { name: "Cocos Islands", code: "61", cc: "CC" },
    { name: "Colombia", code: "57", cc: "CO" },
    { name: "Comoros", code: "269", cc: "KM" },
    { name: "Cook Islands", code: "682", cc: "CK" },
    { name: "Costa Rica", code: "506", cc: "CR" },
    { name: "Croatia", code: "385", cc: "HR" },
    { name: "Cuba", code: "53", cc: "CU" },
    { name: "Curacao", code: "599", cc: "CW" },
    { name: "Cyprus", code: "357", cc: "CY" },
    { name: "Czech Republic", code: "420", cc: "CZ" },
    { name: "Democratic Republic of the Congo", code: "243", cc: "CD" },
    { name: "Denmark", code: "45", cc: "DK" },
    { name: "Djibouti", code: "253", cc: "DJ" },
    { name: "Dominica", code: "1-767", cc: "DM" },
    { name: "Dominican Republic", code: "1-809, 1-829, 1-849", cc: "DO" },
    { name: "East Timor", code: "670", cc: "TL" },
    { name: "Ecuador", code: "593", cc: "EC" },
    { name: "Egypt", code: "20", cc: "EG" },
    { name: "El Salvador", code: "503", cc: "SV" },
    { name: "Equatorial Guinea", code: "240", cc: "GQ" },
    { name: "Eritrea", code: "291", cc: "ER" },
    { name: "Estonia", code: "372", cc: "EE" },
    { name: "Ethiopia", code: "251", cc: "ET" },
    { name: "Falkland Islands", code: "500", cc: "FK" },
    { name: "Faroe Islands", code: "298", cc: "FO" },
    { name: "Fiji", code: "679", cc: "FJ" },
    { name: "Finland", code: "358", cc: "FI" },
    { name: "France", code: "33", cc: "FR" },
    { name: "French Polynesia", code: "689", cc: "PF" },
    { name: "Gabon", code: "241", cc: "GA" },
    { name: "Gambia", code: "220", cc: "GM" },
    { name: "Georgia", code: "995", cc: "GE" },
    { name: "Germany", code: "49", cc: "DE" },
    { name: "Ghana", code: "233", cc: "GH" },
    { name: "Gibraltar", code: "350", cc: "GI" },
    { name: "Greece", code: "30", cc: "GR" },
    { name: "Greenland", code: "299", cc: "GL" },
    { name: "Grenada", code: "1-473", cc: "GD" },
    { name: "Guam", code: "1-671", cc: "GU" },
    { name: "Guatemala", code: "502", cc: "GT" },
    { name: "Guernsey", code: "44-1481", cc: "GG" },
    { name: "Guinea", code: "224", cc: "GN" },
    { name: "Guinea-Bissau", code: "245", cc: "GW" },
    { name: "Guyana", code: "592", cc: "GY" },
    { name: "Haiti", code: "509", cc: "HT" },
    { name: "Honduras", code: "504", cc: "HN" },
    { name: "Hong Kong", code: "852", cc: "HK" },
    { name: "Hungary", code: "36", cc: "HU" },
    { name: "Iceland", code: "354", cc: "IS" },
    { name: "India", code: "91", cc: "IN" },
    { name: "Indonesia", code: "62", cc: "ID" },
    { name: "Iran", code: "98", cc: "IR" },
    { name: "Iraq", code: "964", cc: "IQ" },
    { name: "Ireland", code: "353", cc: "IE" },
    { name: "Isle of Man", code: "44-1624", cc: "IM" },
    { name: "Israel", code: "972", cc: "IL" },
    { name: "Italy", code: "39", cc: "IT" },
    { name: "Ivory Coast", code: "225", cc: "CI" },
    { name: "Jamaica", code: "1-876", cc: "JM" },
    { name: "Japan", code: "81", cc: "JP" },
    { name: "Jersey", code: "44-1534", cc: "JE" },
    { name: "Jordan", code: "962", cc: "JO" },
    { name: "Kazakhstan", code: "7", cc: "KZ" },
    { name: "Kenya", code: "254", cc: "KE" },
    { name: "Kiribati", code: "686", cc: "KI" },
    { name: "Kosovo", code: "383", cc: "XK" },
    { name: "Kuwait", code: "965", cc: "KW" },
    { name: "Kyrgyzstan", code: "996", cc: "KG" },
    { name: "Laos", code: "856", cc: "LA" },
    { name: "Latvia", code: "371", cc: "LV" },
    { name: "Lebanon", code: "961", cc: "LB" },
    { name: "Lesotho", code: "266", cc: "LS" },
    { name: "Liberia", code: "231", cc: "LR" },
    { name: "Libya", code: "218", cc: "LY" },
    { name: "Liechtenstein", code: "423", cc: "LI" },
    { name: "Lithuania", code: "370", cc: "LT" },
    { name: "Luxembourg", code: "352", cc: "LU" },
    { name: "Macau", code: "853", cc: "MO" },
    { name: "Macedonia", code: "389", cc: "MK" },
    { name: "Madagascar", code: "261", cc: "MG" },
    { name: "Malawi", code: "265", cc: "MW" },
    { name: "Malaysia", code: "60", cc: "MY" },
    { name: "Maldives", code: "960", cc: "MV" },
    { name: "Mali", code: "223", cc: "ML" },
    { name: "Malta", code: "356", cc: "MT" },
    { name: "Marshall Islands", code: "692", cc: "MH" },
    { name: "Mauritania", code: "222", cc: "MR" },
    { name: "Mauritius", code: "230", cc: "MU" },
    { name: "Mayotte", code: "262", cc: "YT" },
    { name: "Mexico", code: "52", cc: "MX" },
    { name: "Micronesia", code: "691", cc: "FM" },
    { name: "Moldova", code: "373", cc: "MD" },
    { name: "Monaco", code: "377", cc: "MC" },
    { name: "Mongolia", code: "976", cc: "MN" },
    { name: "Montenegro", code: "382", cc: "ME" },
    { name: "Montserrat", code: "1-664", cc: "MS" },
    { name: "Morocco", code: "212", cc: "MA" },
    { name: "Mozambique", code: "258", cc: "MZ" },
    { name: "Myanmar", code: "95", cc: "MM" },
    { name: "Namibia", code: "264", cc: "NA" },
    { name: "Nauru", code: "674", cc: "NR" },
    { name: "Nepal", code: "977", cc: "NP" },
    { name: "Netherlands", code: "31", cc: "NL" },
    { name: "Netherlands Antilles", code: "599", cc: "AN" },
    { name: "New Caledonia", code: "687", cc: "NC" },
    { name: "New Zealand", code: "64", cc: "NZ" },
    { name: "Nicaragua", code: "505", cc: "NI" },
    { name: "Niger", code: "227", cc: "NE" },
    { name: "Nigeria", code: "234", cc: "NG" },
    { name: "Niue", code: "683", cc: "NU" },
    { name: "North Korea", code: "850", cc: "KP" },
    { name: "Northern Mariana Islands", code: "1-670", cc: "MP" },
    { name: "Norway", code: "47", cc: "NO" },
    { name: "Oman", code: "968", cc: "OM" },
    { name: "Pakistan", code: "92", cc: "PK" },
    { name: "Palau", code: "680", cc: "PW" },
    { name: "Palestine", code: "970", cc: "PS" },
    { name: "Panama", code: "507", cc: "PA" },
    { name: "Papua New Guinea", code: "675", cc: "PG" },
    { name: "Paraguay", code: "595", cc: "PY" },
    { name: "Peru", code: "51", cc: "PE" },
    { name: "Philippines", code: "63", cc: "PH" },
    { name: "Pitcairn", code: "64", cc: "PN" },
    { name: "Poland", code: "48", cc: "PL" },
    { name: "Portugal", code: "351", cc: "PT" },
    { name: "Puerto Rico", code: "1-787, 1-939", cc: "PR" },
    { name: "Qatar", code: "974", cc: "QA" },
    { name: "Republic of the Congo", code: "242", cc: "CG" },
    { name: "Reunion", code: "262", cc: "RE" },
    { name: "Romania", code: "40", cc: "RO" },
    { name: "Russia", code: "7", cc: "RU" },
    { name: "Rwanda", code: "250", cc: "RW" },
    { name: "Saint Barthelemy", code: "590", cc: "BL" },
    { name: "Saint Helena", code: "290", cc: "SH" },
    { name: "Saint Kitts and Nevis", code: "1-869", cc: "KN" },
    { name: "Saint Lucia", code: "1-758", cc: "LC" },
    { name: "Saint Martin", code: "590", cc: "MF" },
    { name: "Saint Pierre and Miquelon", code: "508", cc: "PM" },
    { name: "Saint Vincent and the Grenadines", code: "1-784", cc: "VC" },
    { name: "Samoa", code: "685", cc: "WS" },
    { name: "San Marino", code: "378", cc: "SM" },
    { name: "Sao Tome and Principe", code: "239", cc: "ST" },
    { name: "Saudi Arabia", code: "966", cc: "SA" },
    { name: "Senegal", code: "221", cc: "SN" },
    { name: "Serbia", code: "381", cc: "RS" },
    { name: "Seychelles", code: "248", cc: "SC" },
    { name: "Sierra Leone", code: "232", cc: "SL" },
    { name: "Singapore", code: "65", cc: "SG" },
    { name: "Sint Maarten", code: "1-721", cc: "SX" },
    { name: "Slovakia", code: "421", cc: "SK" },
    { name: "Slovenia", code: "386", cc: "SI" },
    { name: "Solomon Islands", code: "677", cc: "SB" },
    { name: "Somalia", code: "252", cc: "SO" },
    { name: "South Africa", code: "27", cc: "ZA" },
    { name: "South Korea", code: "82", cc: "KR" },
    { name: "South Sudan", code: "211", cc: "SS" },
    { name: "Spain", code: "34", cc: "ES" },
    { name: "Sri Lanka", code: "94", cc: "LK" },
    { name: "Sudan", code: "249", cc: "SD" },
    { name: "Suriname", code: "597", cc: "SR" },
    { name: "Svalbard and Jan Mayen", code: "47", cc: "SJ" },
    { name: "Swaziland", code: "268", cc: "SZ" },
    { name: "Sweden", code: "46", cc: "SE" },
    { name: "Switzerland", code: "41", cc: "CH" },
    { name: "Syria", code: "963", cc: "SY" },
    { name: "Taiwan", code: "886", cc: "TW" },
    { name: "Tajikistan", code: "992", cc: "TJ" },
    { name: "Tanzania", code: "255", cc: "TZ" },
    { name: "Thailand", code: "66", cc: "TH" },
    { name: "Togo", code: "228", cc: "TG" },
    { name: "Tokelau", code: "690", cc: "TK" },
    { name: "Tonga", code: "676", cc: "TO" },
    { name: "Trinidad and Tobago", code: "1-868", cc: "TT" },
    { name: "Tunisia", code: "216", cc: "TN" },
    { name: "Turkey", code: "90", cc: "TR" },
    { name: "Turkmenistan", code: "993", cc: "TM" },
    { name: "Turks and Caicos Islands", code: "1-649", cc: "TC" },
    { name: "Tuvalu", code: "688", cc: "TV" },
    { name: "U.S. Virgin Islands", code: "1-340", cc: "VI" },
    { name: "Uganda", code: "256", cc: "UG" },
    { name: "Ukraine", code: "380", cc: "UA" },
    { name: "United Arab Emirates", code: "971", cc: "AE" },
    { name: "United Kingdom", code: "44", cc: "GB" },
    { name: "United States", code: "1", cc: "US" },
    { name: "Uruguay", code: "598", cc: "UY" },
    { name: "Uzbekistan", code: "998", cc: "UZ" },
    { name: "Vanuatu", code: "678", cc: "VU" },
    { name: "Vatican", code: "379", cc: "VA" },
    { name: "Venezuela", code: "58", cc: "VE" },
    { name: "Vietnam", code: "84", cc: "VN" },
    { name: "Wallis and Futuna", code: "681", cc: "WF" },
    { name: "Western Sahara", code: "212", cc: "EH" },
    { name: "Yemen", code: "967", cc: "YE" },
    { name: "Zambia", code: "260", cc: "ZM" },
    { name: "Zimbabwe", code: "263", cc: "ZW" },
  ];
  const handleClick = () => {
    setIsActive(!isActive);
    timeOutIDS.forEach((id) => clearTimeout(id));
  };

  const onValueChange = (e, title) => {
    setCallBackDto({
      ...callBackDto,
      [title]: e.target.value,
    });
  };
  // const onSubmitClick  = () => {
  // 	const namee = callBackDto.name;
  // 	const no = callBackDto.number;
  // 	var myHeaders = new Headers();
  // 	myHeaders.append("Content-Type", "application/json");
  // 	myHeaders.append("Cookie", "1a99390653=fa937bf8820a337da6a65156d344d3c2; _zcsr_tmp=8c6b538d-4aaf-45da-a252-ed2ea34ed593; crmcsr=8c6b538d-4aaf-45da-a252-ed2ea34ed593");
  // 	var raw = JSON.stringify({
  // 		"Name": { namee },
  // 		"Phone": { no }
  // 	});
  // 	var requestOptions = {
  // 		method: 'POST',
  // 		headers: myHeaders,
  // 		body: raw,
  // 		redirect: 'follow'
  // 	};

  // 	fetch("https://www.zohoapis.com/crm/v2/functions/createrequestcallbackrecord/actions/execute?auth_type=apikey&zapikey=1003.4c1b2b4d3ee5141ee902ac79c05a933c.a83a093eaa7c599a881b3991bf5c5e7b", requestOptions)
  // 		.then(response => response.text())
  // 		.then(result => console.log(result))
  // 		.catch(error => console.log('error', error));
  // }
  const onSubmitClick = async () => {
    const namee = callBackDto.name;
    const no = callBackDto.number;
    const email = callBackDto.email;
    const emailFormat = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (
      (!router.asPath.includes("/exports-project") &&
        !router.asPath.includes("/download-export-catalogue") &&
        (!namee || !no)) ||
      ((router.asPath.includes("/exports-project") ||
        router.asPath.includes("/download-export-catalogue")) &&
        (!namee || !no || !email))
    ) {
      setCallBackDto({
        ...callBackDto,
        error: "All Fields are required",
      });
    } else if (
      callBackDto.pincode &&
      !router.asPath.includes("exports-project") &&
      !router.asPath.includes("/download-export-catalogue") &&
      !callBackDto.pincode.length == 6
    ) {
      setCallBackDto({
        ...callBackDto,
        error: "Invalid Pincode",
      });
    } else if (
      (router.asPath.includes("/exports-project") ||
        router.asPath.includes("/download-export-catalogue")) &&
      email &&
      !emailFormat.test(email)
    ) {
      setCallBackDto({
        ...callBackDto,
        error: "Invalid Email format",
      });
    } else {
      setCallBackDto({
        ...callBackDto,
        error: "",
        loading: true,
      });
      //gtagConversionEvent()
      let response = await requestCallback({
        name: namee,
        phone: `${callBackDto.countryCode}${no}`,
        pincode: callBackDto.pincode,
        email: email,
      });
      if (response?.message) {
        localStorage.setItem("leadfilled", true);
        swal(response?.message);
        setCallBackDto({
          ...callBackDto,
          error: "",
          loading: false,
        });
      } else {
        swal(response.data.request_callback.msg);
        setCallBackDto({
          name: "",
          number: "",
          error: "",
          loading: false,
        });
      }
    }
  };
  const submitGetInTouch = async (subsource) => {
    const namee = touch.name;
    const no = touch.number;
    const pin = touch.pincode;
    const email = touch.email;
    const emailFormat = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    //   console.log("PIN", pin)
    if (
      (!router.asPath.includes("/exports-project") &&
        !router.asPath.includes("/download-export-catalogue") &&
        (!namee || !no)) ||
      ((router.asPath.includes("/exports-project") ||
        router.asPath.includes("/download-export-catalogue")) &&
        (!namee || !no || !email))
    ) {
      setTouch({
        ...touch,
        error: "All fields are required",
      });
    } else if (
      pin &&
      !router.asPath.includes("/exports-project") &&
      !router.asPath.includes("/download-export-catalogue") &&
      String(pin).length !== 6
    ) {
      setTouch({
        ...touch,
        error: "Invalid Pincode",
      });
    } else if (String(no).length > 13 || String(no).length < 9) {
      setTouch({
        ...touch,
        error: "Invalid Phone number",
      });
    } else if (
      (router.asPath.includes("/exports-project") ||
        router.asPath.includes("/download-export-catalogue")) &&
      email &&
      !emailFormat.test(email)
    ) {
      setTouch({
        ...touch,
        error: "Invalid Email format",
      });
    } else {
      setTouch({
        ...touch,
        error: "",
        loading: true,
      });

      //   //gtagConversionEvent();

      try {
        let response = await getIntouch({
          name: namee,
          phone: no,
          pincode: pin,
          email: email,
          call_id: subsource,
        });

        if (response?.message) {
          localStorage.setItem("leadfilled", true);
          swal(response?.message);
        } else {
          console.log(response.data.get_in_touch, "response.data.get_in_touch");
          if (response.data.get_in_touch.lead_id) {
            uploadAudio(response.data.get_in_touch.lead_id);
          }
          swal(response.data.get_in_touch.msg);
          handleClick();
          setGetInTouch(false);
          settarrekImage(false);
          localStorage.setItem("TarikClosed", true);
          setTouch({
            name: "",
            number: "",
            pincode: "",
            error: "",
            loading: false,
            email: "",
          });
        }
      } catch (error) {
        console.log("Error submitting form:", error);
        setTouch({
          ...touch,
          error: "Error submitting form",
          loading: false,
        });
      }
    }
  };
  let setleadpopop = () => {
    const startTimeString = localStorage.getItem("startTime");
    let startTime =
      startTimeString &&
      new Date(startTimeString).toLocaleDateString() ==
        new Date().toLocaleDateString()
        ? new Date(startTimeString)
        : new Date();
    const elapsedTime = new Date() - startTime;
    //  if (elapsedTime < 15000) {
    // 	setTimeout(() => {
    // 		if(!localStorage.getItem('leadSkiped')){
    // 			setTimePopup(true);
    // 			setGetInTouch(true);
    // 		}
    // 	}, 15000 - elapsedTime);
    // } else if (elapsedTime < 60000) {
    // 	setTimeout(() => {
    // 		if(!localStorage.getItem('leadSkiped')){
    // 			setTimePopup(true);
    // 			setGetInTouch(true);
    // 		}
    // 	}, 60000 - elapsedTime);
    // } else if (elapsedTime < 120000) {
    // 	setTimeout(() => {
    // 		if(!localStorage.getItem('leadSkiped')){
    // 			setTimePopup(true);
    // 			setGetInTouch(true);}
    // 		}, 120000 - elapsedTime);
    // 	} else if (elapsedTime < 300000) {
    // 		setTimeout(() => {
    // 			if(!localStorage.getItem('leadSkiped')){
    // 				setTimePopup(true);
    // 				setGetInTouch(true);
    // 			}
    // 		}, 300000 - elapsedTime);
    // 	}
    let arrr = [];
    if (elapsedTime < 45000 && !props?.catalougePage) {
      arrr.push(
        setTimeout(() => {
          if (!localStorage.getItem("leadfilled")) {
            setTimePopup(true);
            setGetInTouch(true);
          }
        }, 45000 - elapsedTime)
      );
    } else if (elapsedTime < 60000 && !props?.catalougePage) {
      arrr.push(
        setTimeout(() => {
          if (!localStorage.getItem("leadfilled")) {
            setTimePopup(true);
            setGetInTouch(true);
          }
        }, 60000 - elapsedTime)
      );
    } else if (elapsedTime < 120000 && !props?.catalougePage) {
      arrr.push(
        setTimeout(() => {
          if (!localStorage.getItem("leadfilled")) {
            setTimePopup(true);
            setGetInTouch(true);
          }
        }, 120000 - elapsedTime)
      );
    } else if (elapsedTime < 300000 && !props?.catalougePage) {
      arrr.push(
        setTimeout(() => {
          if (!localStorage.getItem("leadfilled")) {
            setTimePopup(true);
            setGetInTouch(true);
          }
        }, 300000 - elapsedTime)
      );
    }
    settimeOutIDS(arrr);
    localStorage.setItem("startTime", startTime.toString());
  };
  const popoptarek = () => {
    if (document.referrer) {
      const referrerURL = new URL(document.referrer);
      if (referrerURL.hostname === window.location.hostname) {
        console.log("User came from another page on this site");
      } else {
        setTimeout(() => {
          settarrekImage(true);
        }, 3000);
      }
    } else {
      setTimeout(() => {
        settarrekImage(true);
      }, 3000);
    }
  };
  useEffect(() => {
    if (
      !cookie.get("skipLocation") &&
      !cookie.get("pincode") &&
      props?.displayLocationPopop
    ) {
      setLocationPopop(true);
    } else {
      setleadpopop();
      popoptarek();
    }
    const handleScroll = () => {
      const scrollPosition =
        window.scrollY || document.documentElement.scrollTop;

      // Check if the scroll position is greater than or equal to 1000px
      if (scrollPosition >= 1000 && !localStorage.getItem("schrollSkip")) {
        setIsActive(true);
      }
    };

    // Attach the event listener when the component mounts
    // window.addEventListener('scroll', handleScroll);

    // Clean up the event listener when the component unmounts
    return () => {
      // clearTimeout(alertAfter15Sec);
      // clearTimeout(alertAfter1Min);
      // clearTimeout(alertAfter2Min);
      // clearTimeout(alertAfter5Min)
    };
  }, []);
  return (
    <>
      <div
        className={
          isActive ? "right_sidebar_form active" : "right_sidebar_form"
        }
      >
        <Form
          onSubmit={(e) => {
            e.preventDefault();
            submitGetInTouch("Get In Touch");
          }}
        >
          <div class="close-form" onClick={handleClick}>
            <FaAngleRight />
          </div>
          <Form.Group className="mb-3" controlId="formBasicEmail">
            <Form.Control
              type="text"
              value={touch.name}
              placeholder="Name*"
              onChange={(e) => {
                setTouch({
                  ...touch,
                  name: e.target.value,
                });
              }}
            />
          </Form.Group>
          <Form.Group className="mb-3" controlId="formBasicPassword">
            {router.asPath.includes("/exports-project") ||
            router.asPath.includes("/download-export-catalogue") ? (
              <div className="export-page-getInTouch">
                <Dropdown
                  onSelect={(selectedCountryCode) => {
                    const selectedCountry = countries.find(
                      (country) => country.code === selectedCountryCode
                    );
                    setTouch({
                      ...touch,
                      countryCode: selectedCountryCode,
                      cc: selectedCountry ? selectedCountry.cc : "", // Update cc based on selected country
                    });
                  }}
                >
                  <Dropdown.Toggle variant="secondary" id="dropdown-basic">
                    {touch.countryCode && (
                      <div>
                        <ReactCountryFlag countryCode={touch.cc} svg />
                        {` (${touch.countryCode})`}
                      </div>
                    )}
                    {!touch.countryCode && "Country Code"}
                  </Dropdown.Toggle>

                  <Dropdown.Menu
                    style={{ maxHeight: "200px", overflowY: "auto" }}
                  >
                    {countries.map((country) => (
                      <Dropdown.Item key={country.code} eventKey={country.code}>
                        <div>
                          <ReactCountryFlag countryCode={country.cc} svg />
                          {`${country.name} (${country.code})`}
                        </div>
                      </Dropdown.Item>
                    ))}
                  </Dropdown.Menu>
                </Dropdown>

                <Form.Control
                  type="number"
                  placeholder="Mobile Number*"
                  onChange={(e) => {
                    setTouch({
                      ...touch,
                      number: `${touch.countryCode || ""}${e.target.value}`, // Append the country code
                    });
                  }}
                />
              </div>
            ) : (
              <Form.Control
                type="number"
                placeholder="Mobile Number*"
                onChange={(e) => {
                  setTouch({
                    ...touch,
                    number: `${touch.countryCode || ""}${e.target.value}`, // Append the country code
                  });
                }}
              />
            )}
          </Form.Group>
          {router.asPath.includes("/exports-project") ||
          router.asPath.includes("/download-export-catalogue") ? (
            <Form.Group className="mb-3" controlId="formBasicPassword">
              <Form.Control
                type="email"
                value={touch.email}
                placeholder="Email"
                onChange={(e) => {
                  setTouch({
                    ...touch,
                    email: e.target.value,
                  });
                }}
              />
            </Form.Group>
          ) : (
            <Form.Group className="mb-3" controlId="formBasicPassword">
              <Form.Control
                type="number"
                value={touch.pincode}
                placeholder="Pincode (Optional)"
                onChange={(e) => {
                  setTouch({
                    ...touch,
                    pincode: e.target.value,
                  });
                }}
              />
            </Form.Group>
          )}
          {touch.error && <p className="error text-danger">{touch.error}</p>}

          <Button
            variant="primary"
            type="submit"
            disabled={touch.loading}
            style={{ width: "100%" }}
          >
            {touch.loading ? "Submitting" : "Submit"}
          </Button>
        </Form>
        <div className="getintouch_siadebar" onClick={handleClick}>
          <div class="foot-icon-bx">
            <img src="/get-touch-thumbnail.svg" alt="get in touch" />
          </div>
          <p>Talk to Expert</p>
          <FaAngleRight />
        </div>
      </div>
      {router.asPath == "/exports-project" ||
      router.asPath == "/download-export-catalogue" ? (
        <div className="mobile_view_footericon">
          <div class="foot-info-col">
            <div class="foot-icon-bx">
              <a href="ttel:+91-9599770470" aria-level="phone">
                <FaPhone />
              </a>
            </div>
            <p>Phone</p>
          </div>
          <div class="foot-info-col" id="Whatsapp_button_mobile_footer">
            <div class="foot-icon-bx">
              <a
                href="https://wa.me/919599770470?text=Hi, I am interested in Orientbell Tiles. I am currently browsing https://www.orientbell.com/"
                target="_blank"
              >
                <FaWhatsapp />
              </a>
            </div>
            <p>Whatsapp</p>
          </div>
        </div>
      ) : (
        <div className="mobile_view_footericon">
          <div class="foot-info-col">
            <div class="foot-icon-bx">
              <a href="tel:1800 208 1015" aria-level="phone">
                <FaPhone />
              </a>
            </div>
            <p>Phone</p>
          </div>
          <div class="foot-info-col" id="Whatsapp_button_mobile_footer">
            <div class="foot-icon-bx">
              <a
                href="https://wa.me/918750733333?text=Hi, I am interested in Orientbell Tiles. I am currently browsing https://www.orientbell.com/"
                target="_blank"
              >
                <FaWhatsapp />
              </a>
            </div>
            <p>Whatsapp</p>
          </div>
        </div>
      )}
      <div class="mobile-foot-category-row" style={{ display: "none" }}>
        <div class="foot-info-col" id="Store_locator_mobile_footer">
          <a href="https://www.orientbell.com/store-locator">
            <svg
              width="18"
              height="17"
              viewBox="0 0 18 17"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M17.0052 0.981934V2.99216H1.0169V0.981934H17.0052ZM17.987 10.9863H17.0052V16.9703H14.995V10.9863H11.0213V16.9703H1.0169V10.9863H0.0351562V8.9761L1.0169 3.9739H17.0052L17.987 8.9761V10.9863ZM9.01106 14.96V10.9863H3.02712V14.96H9.01106Z"
                fill="#BFBFBF"
              />
            </svg>
            <span>Stores</span>
          </a>
        </div>
        <div class="foot-info-col" id="Catalogue_button_mobile_footer">
          <a
            href="https://www.orientbell.com/download-catalogue"
            aria-label="category"
          >
            <svg
              width="16"
              height="18"
              viewBox="0 0 16 18"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M0.414062 14.8092C0.414063 15.4723 0.677455 16.1082 1.1463 16.577C1.61514 17.0459 2.25102 17.3092 2.91406 17.3092H12.9141C13.5771 17.3092 14.213 17.0459 14.6818 16.577C15.1507 16.1082 15.4141 15.4723 15.4141 14.8092V6.33258C15.4137 5.66988 15.1502 5.03444 14.6816 4.56591L11.4916 1.37424C11.0227 0.905677 10.3869 0.642499 9.72406 0.642578H2.91406C2.25102 0.642578 1.61514 0.90597 1.1463 1.37481C0.677455 1.84365 0.414063 2.47954 0.414062 3.14258V14.8092ZM8.7474 8.97591C8.7474 8.7549 8.6596 8.54294 8.50332 8.38666C8.34704 8.23038 8.13508 8.14258 7.91406 8.14258C7.69305 8.14258 7.48109 8.23038 7.32481 8.38666C7.16853 8.54294 7.08073 8.7549 7.08073 8.97591V11.9642L6.00323 10.8867C5.84606 10.7349 5.63556 10.651 5.41706 10.6529C5.19856 10.6547 4.98955 10.7424 4.83505 10.8969C4.68054 11.0514 4.5929 11.2604 4.591 11.4789C4.5891 11.6974 4.6731 11.9079 4.8249 12.0651L7.3249 14.5651C7.48117 14.7213 7.69309 14.8091 7.91406 14.8091C8.13503 14.8091 8.34696 14.7213 8.50323 14.5651L11.0032 12.0651C11.155 11.9079 11.239 11.6974 11.2371 11.4789C11.2352 11.2604 11.1476 11.0514 10.9931 10.8969C10.8386 10.7424 10.6296 10.6547 10.4111 10.6529C10.1926 10.651 9.98206 10.7349 9.8249 10.8867L8.7474 11.9642V8.97591Z"
                fill="#BFBFBF"
              />
            </svg>
            <span>Catalogue</span>
          </a>
        </div>
      </div>
      <div className="review-Catalouge">
        <div className="container">
          <div className="d-catalog-row">
            {router.asPath.includes("/exports-project") ||
            router.asPath.includes("/download-export-catalogue") ? (
              <a href={`${config.HEADLESS_BASE_URL}/download-export-catalogue`}>
                <img src="/brouchure.png" alt="Image" /> DOWNLOAD CATALOGUE
              </a>
            ) : (
              <a href={`${config.HEADLESS_BASE_URL}/download-catalogue`}>
                <img src="/brouchure.png" alt="Image" /> DOWNLOAD CATALOGUE
              </a>
            )}
          </div>
        </div>
      </div>
      <div className="fromUs-section">
        <div className="container">
          <div className="ft-fromUs-row flex-class">
            <div className="fr-Title">Request a Callback</div>
            <div className="us-form-col">
              <form className="flex-class">
                <div className="form-group">
                  <input
                    type="text"
                    value={callBackDto.name}
                    onChange={(e) => {
                      onValueChange(e, "name");
                    }}
                    placeholder="Name"
                    className="input-bx"
                  />
                </div>
                <div className="form-group">
                  {(router.asPath.includes("exports-project") ||
                    router.asPath.includes("download-export-catalogue")) && (
                    <Dropdown
                      onSelect={(selectedCountryCode) => {
                        const selectedCountry = countries.find(
                          (country) => country.code === selectedCountryCode
                        );
                        setCallBackDto({
                          ...callBackDto,
                          countryCode: selectedCountryCode,
                          cc: selectedCountry ? selectedCountry.cc : "",
                        });
                      }}
                    >
                      <Dropdown.Toggle variant="secondary" id="dropdown-basic">
                        {callBackDto.countryCode ? (
                          <div>
                            <ReactCountryFlag
                              countryCode={callBackDto.cc}
                              svg
                            />
                            {` (${callBackDto.countryCode})`}
                          </div>
                        ) : (
                          !touch.callBackDto && "Country"
                        )}
                        {/* {!touch.countryCode && 'Country Code'} */}
                      </Dropdown.Toggle>
                      <Dropdown.Menu
                        style={{ maxHeight: "200px", overflowY: "auto" }}
                      >
                        {/* Add your country options with flags */}
                        {countries.map((country) => (
                          <Dropdown.Item
                            key={country.code}
                            eventKey={country.code}
                          >
                            <div>
                              <ReactCountryFlag countryCode={country.cc} svg />
                              {`${country.name} (${country.code})`}
                            </div>
                          </Dropdown.Item>
                        ))}
                      </Dropdown.Menu>
                    </Dropdown>
                  )}
                  <input
                    type="number"
                    value={callBackDto.number}
                    onChange={(e) => {
                      onValueChange(e, "number");
                    }}
                    placeholder="Mobile Number"
                    className="input-bx"
                  />
                </div>
                {router.asPath.includes("/exports-project") ||
                router.asPath.includes("/download-export-catalogue") ? (
                  <div className="form-group">
                    <input
                      type="email"
                      value={callBackDto.email}
                      onChange={(e) => {
                        onValueChange(e, "email");
                      }}
                      placeholder="Email"
                      className="input-bx"
                    />
                  </div>
                ) : (
                  <div className="form-group">
                    <input
                      type="number"
                      value={callBackDto.pincode}
                      onChange={(e) => {
                        onValueChange(e, "pincode");
                      }}
                      placeholder="Pincode (Optional)"
                      className="input-bx"
                    />
                  </div>
                )}
                <div className="form-group button">
                  {callBackDto.loading ? (
                    <Button className="ft-submit-btn">Submitting</Button>
                  ) : (
                    <Button
                      className="ft-submit-btn"
                      onClick={() => onSubmitClick()}
                    >
                      Submit
                    </Button>
                  )}
                </div>
                {callBackDto.error && (
                  <p className="error text-danger">{callBackDto.error}</p>
                )}
              </form>
            </div>
          </div>
        </div>
      </div>
      <footer>
        <div className="container">
          <div className="footer-main">
            <div className="footer-top flex-class">
              <div className="foot-col">
                <div
                  className={
                    isClassToggled[0] ? "f-heading active-link" : "f-heading"
                  }
                  onClick={() => toggleClass(0)}
                >
                  <h6>Tiles</h6>
                </div>
                <ul>
                  <li>
                    <a href="https://www.orientbell.com/tiles/floor-tiles">
                      Floor Tiles
                    </a>
                  </li>
                  <li>
                    <a href="https://www.orientbell.com/tiles/wall-tiles">
                      Wall Tiles
                    </a>
                  </li>
                </ul>
                <div class="foot-box">
                  <div
                    className={
                      isClassToggled[16] ? "f-heading active-link" : "f-heading"
                    }
                    onClick={() => toggleClass(16)}
                  >
                    <h6>Locations</h6>
                  </div>
                  <ul>
                    <li>
                      <a href="https://www.orientbell.com/tiles/delhi">Delhi</a>
                    </li>
                    <li>
                      <a href="https://www.orientbell.com/tiles/mumbai">
                        Mumbai
                      </a>
                    </li>
                    <li>
                      <a href="https://www.orientbell.com/tiles/bangalore">
                        Bengaluru
                      </a>
                    </li>
                    <li>
                      <a href="https://www.orientbell.com/tiles/darjeeling">
                        Darjeeling
                      </a>
                    </li>
                    <li>
                      <a href="https://www.orientbell.com/tiles/alipurduar">
                        Alipurduar
                      </a>
                    </li>
                    <li>
                      <a href="https://www.orientbell.com/tiles/jangipur">
                        Jangipur
                      </a>
                    </li>
                    <li>
                      <a href="https://www.orientbell.com/tiles/raiganj">
                        Raiganj
                      </a>
                    </li>
                    <li>
                      <a href="https://www.orientbell.com/tiles/hyderabad">
                        Hyderabad
                      </a>
                    </li>
                    <li>
                      <a href="https://www.orientbell.com/tiles/chennai">
                        Chennai
                      </a>
                    </li>
                    <li>
                      <a href="https://www.orientbell.com/tiles/kolkata">
                        Kolkata
                      </a>
                    </li>
                    <li>
                      <a href="https://www.orientbell.com/tiles/lucknow">
                        Lucknow
                      </a>
                    </li>
                    <li>
                      <a href="https://www.orientbell.com/tiles/pune">Pune</a>
                    </li>
                    <li>
                      <a href="https://www.orientbell.com/tiles/bhopal">
                        Bhopal
                      </a>
                    </li>
                    <li>
                      <a href="https://www.orientbell.com/tiles/jabalpur">
                        Jabalpur
                      </a>
                    </li>
                    <li>
                      <a href="https://www.orientbell.com/tiles/indore">
                        Indore
                      </a>
                    </li>
                    <li>
                      <a href="https://www.orientbell.com/tiles/nagpur">
                        Nagpur
                      </a>
                    </li>
                    <li>
                      <a href="https://www.orientbell.com/tiles/coimbatore">
                        Coimbatore
                      </a>
                    </li>
                    <li>
                      <a href="https://www.orientbell.com/tiles/patna">Patna</a>
                    </li>
                    <li>
                      <a href="https://www.orientbell.com/tiles/ernakulam">
                        Ernakulam
                      </a>
                    </li>

                    <li>
                      <a href=" https://www.orientbell.com/tiles/narnaul">
                        Narnaul
                      </a>
                    </li>
                    <li>
                      <a href=" https://www.orientbell.com/tiles/mahendragarh">
                        Mahendragarh
                      </a>
                    </li>
                    <li>
                      <a href=" https://www.orientbell.com/tiles/sohna">
                        Sohna
                      </a>
                    </li>
                    <li>
                      <a href=" https://www.orientbell.com/tiles/nuh">Nuh</a>
                    </li>
                    <li>
                      <a href=" https://www.orientbell.com/tiles/noida">
                        Noida
                      </a>
                    </li>
                    <li>
                      <a href=" https://www.orientbell.com/tiles/gurugram">
                        Gurugram
                      </a>
                    </li>
                    <li>
                      <a href=" https://www.orientbell.com/tiles/pataudi">
                        Pataudi
                      </a>
                    </li>
                    <li>
                      <a href=" https://www.orientbell.com/tiles/bhiwadi">
                        Bhiwadi
                      </a>
                    </li>
                    <li>
                      <a href=" https://www.orientbell.com/tiles/manesar">
                        Manesar
                      </a>
                    </li>
                    <li>
                      <a href=" https://www.orientbell.com/tiles/dharuhera">
                        Dharuhera
                      </a>
                    </li>
                    <li>
                      <a href=" https://www.orientbell.com/tiles/farukhnagar">
                        Farukhnagar
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="foot-col">
                <div
                  className={
                    isClassToggled[1] ? "f-heading active-link" : "f-heading"
                  }
                  onClick={() => toggleClass(1)}
                >
                  <h6>Tiles by Area</h6>
                </div>
                <ul>
                  <li>
                    <a href="https://www.orientbell.com/tiles/bathroom-tiles">
                      Bathroom Tiles
                    </a>
                  </li>
                  <li>
                    <a href="https://www.orientbell.com/tiles/kitchen-tiles">
                      Kitchen Tiles
                    </a>
                  </li>
                  <li>
                    <a href="https://www.orientbell.com/tiles/parking-tiles">
                      Parking Tiles
                    </a>
                  </li>
                  <li>
                    <a href="https://www.orientbell.com/tiles/elevation-tiles">
                      Elevation Tiles
                    </a>
                  </li>
                  <li>
                    <a href="https://www.orientbell.com/tiles/bedroom-tiles">
                      Bedroom Tiles
                    </a>
                  </li>
                  <li>
                    <a href="https://www.orientbell.com/tiles/outdoor-tiles">
                      Outdoor Tiles
                    </a>
                  </li>
                  <li>
                    <a href="https://www.orientbell.com/tiles/terrace-tiles">
                      Terrace Tiles
                    </a>
                  </li>
                  <li>
                    <a href="https://www.orientbell.com/tiles/living-room-tiles">
                      Living Room Tiles
                    </a>
                  </li>
                  <li>
                    <a href="https://www.orientbell.com/tiles/balcony-tiles">
                      Balcony Tiles
                    </a>
                  </li>
                  <li>
                    <a href="https://www.orientbell.com/tiles/swimming-pool-tiles">
                      Swimming Pool Tiles
                    </a>
                  </li>
                  <li>
                    <a href="https://www.orientbell.com/tiles/porch-tiles">
                      Porch Tiles
                    </a>
                  </li>
                  <li>
                    <a href="https://www.orientbell.com/tiles/office-tiles">
                      Office Tiles
                    </a>
                  </li>
                  <li>
                    <a href="https://www.orientbell.com/tiles/pathway-tiles">
                      Pathway Tiles
                    </a>
                  </li>
                  <li>
                    <a href="https://www.orientbell.com/tiles/dining-room-tiles">
                      Dining Room Tiles
                    </a>
                  </li>
                  <li>
                    <a href="https://www.orientbell.com/tiles/commercial-tiles">
                      Commercial Tiles
                    </a>
                  </li>
                  <li>
                    <a href="https://www.orientbell.com/tiles/bar-tiles">
                      Bar Tiles
                    </a>
                  </li>
                  <li>
                    <a href="https://www.orientbell.com/tiles/restaurant-tiles">
                      Restaurant Tiles
                    </a>
                  </li>
                  <li>
                    <a href="https://www.orientbell.com/tiles/hospital-tiles">
                      Hospital Tiles
                    </a>
                  </li>
                  <li>
                    <a href="https://www.orientbell.com/tiles/accent-tiles">
                      Accent Tiles
                    </a>
                  </li>
                  <li>
                    <a href="https://www.orientbell.com/tiles/automotive-tiles">
                      Automotive Tiles
                    </a>
                  </li>
                  <li>
                    <a href="https://www.orientbell.com/tiles/school-tiles">
                      School Tiles
                    </a>
                  </li>
                  <li>
                    <a href="https://www.orientbell.com/tiles/high-traffic-tiles">
                      High Traffic Tiles
                    </a>
                  </li>
                  <li>
                    <a href="https://www.orientbell.com/tiles/step-stairs-tiles">
                      Stairs Tiles
                    </a>
                  </li>
                  <li>
                    <a href="https://www.orientbell.com/tiles/pooja-room-tiles">
                      Pooja Room Tiles
                    </a>
                  </li>
                  <li>
                    <a href="https://www.orientbell.com/tiles/large-tiles">
                      Large Tiles
                    </a>
                  </li>
                  <li>
                    <a href="https://www.orientbell.com/tiles/slab-tiles">
                      Slab Tiles
                    </a>
                  </li>
                  <li>
                    <a href="https://www.orientbell.com/tiles/tile-collection/gvt-anti-skid-inspire-safegrip">
                      GVT Anti Skid
                    </a>
                  </li>
                  <li>
                    <a href="https://www.orientbell.com/tiles/kitchen-tiles/white-kitchen-tiles">
                      White Kitchen Tiles
                    </a>
                  </li>
                  <li>
                    <a href="https://www.orientbell.com/tiles/kitchen-tiles/blue-kitchen-tiles">
                      Blue Kitchen Tiles
                    </a>
                  </li>
                  <li>
                    <a href="https://www.orientbell.com/tiles/kitchen-tiles/green-kitchen-tiles">
                      Green Kitchen Tiles
                    </a>
                  </li>
                  <li>
                    <a href="https://www.orientbell.com/tiles/kitchen-tiles/black-kitchen-tiles">
                      Black Kitchen Tiles
                    </a>
                  </li>
                  <li>
                    <a href="https://www.orientbell.com/tiles/dolphin-tiles">
                      Dolphin Tiles
                    </a>
                  </li>
                </ul>
              </div>
              <div className="foot-col">
                <div
                  className={
                    isClassToggled[2] ? "f-heading active-link" : "f-heading"
                  }
                  onClick={() => toggleClass(2)}
                >
                  <h6>Tiles by Design</h6>
                </div>
                <ul>
                  <li>
                    <a href="https://www.orientbell.com/tiles/3d-tiles">
                      3d Tiles
                    </a>
                  </li>
                  <li>
                    <a href="https://www.orientbell.com/tiles/wooden-tiles">
                      {" "}
                      Wooden Tiles
                    </a>
                  </li>
                  <li>
                    <a href="https://www.orientbell.com/tiles/marble-tiles">
                      Marble Tiles
                    </a>
                  </li>
                  <li>
                    <a href="https://www.orientbell.com/tiles/texture-tiles">
                      {" "}
                      Texture Tiles
                    </a>
                  </li>
                  <li>
                    <a href="https://www.orientbell.com/tiles/mosaic-tiles">
                      Mosaic Tiles
                    </a>
                  </li>
                  <li>
                    <a href="https://www.orientbell.com/tiles/granite-tiles">
                      Granite Tiles
                    </a>
                  </li>
                  <li>
                    <a href="https://www.orientbell.com/tiles/stone-tiles">
                      {" "}
                      Stone Tiles
                    </a>
                  </li>
                  <li>
                    <a href="https://www.orientbell.com/tiles/pattern-tiles">
                      Pattern Tiles
                    </a>
                  </li>
                  <li>
                    <a href="https://www.orientbell.com/tiles/cement-tiles">
                      Cement Tiles
                    </a>
                  </li>
                  <li>
                    <a href="https://www.orientbell.com/tiles/flower-tiles">
                      {" "}
                      Flower Tiles
                    </a>
                  </li>
                  <li>
                    <a href="https://www.orientbell.com/tiles/travertine-tiles">
                      Travertine Tiles
                    </a>
                  </li>
                  <li>
                    <a href="https://www.orientbell.com/tiles/slate-tiles">
                      {" "}
                      Slate Tiles{" "}
                    </a>
                  </li>
                  <li>
                    <a href="https://www.orientbell.com/tiles/statuario-tiles">
                      Statuario Tiles
                    </a>
                  </li>
                  <li>
                    <a href="https://www.orientbell.com/tiles/plain-tiles">
                      {" "}
                      Plain Tiles
                    </a>
                  </li>
                  <li>
                    <a href="https://www.orientbell.com/tiles/onyx-tiles">
                      {" "}
                      Onyx Tiles{" "}
                    </a>
                  </li>
                  <li>
                    <a href="https://www.orientbell.com/tiles/metallic-tiles">
                      Metallic Tiles
                    </a>
                  </li>
                  <li>
                    <a href="https://www.orientbell.com/tiles/bottochino-tiles">
                      Bottochino Tiles
                    </a>
                  </li>
                  <li>
                    <a href="https://www.orientbell.com/tiles/book-match-tiles">
                      Book Match Tiles
                    </a>
                  </li>
                  <li>
                    <a href="https://www.orientbell.com/tiles/geometric-tiles">
                      Geometric Tiles
                    </a>
                  </li>
                  <li>
                    <a href="https://www.orientbell.com/tiles/carrara-tiles">
                      Carrara Tiles
                    </a>
                  </li>
                  <li>
                    <a href="https://www.orientbell.com/tiles/abstract-tiles">
                      {" "}
                      Abstract Tiles
                    </a>
                  </li>
                  <li>
                    <a href="https://www.orientbell.com/tiles/monochrome-tiles">
                      Monochrome Tiles
                    </a>
                  </li>
                  <li>
                    <a href="https://www.orientbell.com/tiles/stylized-tiles">
                      Stylized Tiles
                    </a>
                  </li>
                  <li>
                    <a href="https://www.orientbell.com/tiles/brick-tiles">
                      Brick Tiles
                    </a>
                  </li>
                  <li>
                    <a href="https://www.orientbell.com/tiles/hexagonal-tiles">
                      Hexagonal Tiles
                    </a>
                  </li>
                  <li>
                    <a href="https://www.orientbell.com/tiles/limestone-tiles">
                      Limestone Tiles
                    </a>
                  </li>
                  <li>
                    <a href="https://www.orientbell.com/tiles/wooden-plank-tiles">
                      Plank Tiles
                    </a>
                  </li>
                </ul>
              </div>
              <div className="foot-col">
                <div
                  className={
                    isClassToggled[3] ? "f-heading active-link" : "f-heading"
                  }
                  onClick={() => toggleClass(3)}
                >
                  <h6>Tiles by Type</h6>
                </div>
                <ul>
                  <li>
                    <a href="https://www.orientbell.com/tiles/vitrified-tiles">
                      Vitrified Tiles
                    </a>
                  </li>
                  <li>
                    <a href="https://www.orientbell.com/tiles/ceramic-tiles">
                      Ceramic Tiles
                    </a>
                  </li>
                  <li>
                    <a href="https://www.orientbell.com/tiles/porcelain-tiles">
                      Porcelain Tiles
                    </a>
                  </li>
                  <li>
                    <a href="https://www.orientbell.com/tiles/designer-tiles">
                      Designer Tiles
                    </a>
                  </li>
                  <li>
                    <a href="https://www.orientbell.com/tiles/anti-skid-tiles">
                      Anti Skid Tiles
                    </a>
                  </li>
                  <li>
                    <a href="https://www.orientbell.com/tiles/digital-tiles">
                      {" "}
                      Digital Tiles
                    </a>
                  </li>
                  <li>
                    <a href="https://www.orientbell.com/tiles/double-charge-tiles">
                      Double Charge Tiles
                    </a>
                  </li>
                  <li>
                    <a href="https://www.orientbell.com/tiles/vitrified-tiles/gvt-tiles">
                      GVT Tiles
                    </a>
                  </li>
                  <li>
                    <a href="https://www.orientbell.com/tiles/cool-tiles">
                      {" "}
                      Cool Tiles
                    </a>
                  </li>
                  <li>
                    <a href="https://www.orientbell.com/tiles/highlighter-tiles">
                      Highlighter Tiles
                    </a>
                  </li>
                  <li>
                    <a href="https://www.orientbell.com/tiles/vitrified-tiles/pgvt-tiles ">
                      PGVT Tiles{" "}
                    </a>
                  </li>
                  <li>
                    <a href="https://www.orientbell.com/tiles/vitrified-tiles/full-body-vitrified-tiles">
                      Full Body Vitrified Tiles
                    </a>
                  </li>
                  <li>
                    <a href="https://www.orientbell.com/tiles/vitrified-tiles/polished-vitrified-tiles">
                      Polished Vitrified Tiles{" "}
                    </a>
                  </li>
                  <li>
                    <a href="https://www.orientbell.com/tiles/germ-free-tiles">
                      Germ Free Tiles
                    </a>
                  </li>
                  <li>
                    <a href="https://www.orientbell.com/tiles/vitrified-tiles/dgvt-tiles">
                      DGVT Tiles
                    </a>
                  </li>
                  <li>
                    <a href="https://www.orientbell.com/tiles/forever-tiles">
                      Scratch Free Tile(Forever Tile)
                    </a>
                  </li>
                  <li>
                    <a href="https://www.orientbell.com/tiles/vitrified-tiles/digital-glazed-vitrified-tiles">
                      Double Glazed Vitrified Tiles
                    </a>
                  </li>
                  <li>
                    <a href="https://www.orientbell.com/tiles/stain-resistant-tiles">
                      Stain Resistant Tiles
                    </a>
                  </li>
                  <li>
                    <a href="https://www.orientbell.com/tiles/nano-tiles">
                      Nano Tiles
                    </a>
                  </li>
                  {/* <li><a href="https://www.orientbell.com/tiles/printed-double-charge-tiles">Printed Double Charge Tiles</a></li> */}
                  <li>
                    <a href="https://www.orientbell.com/tiles/non-digital-tiles">
                      {" "}
                      Non Digital Tiles
                    </a>
                  </li>
                  {/* <li><a href="https://www.orientbell.com/tiles/kerinox-tiles">Kerinox Tiles</a></li> */}

                  <li>
                    <a href="https://www.orientbell.com/tiles/anti-static-tiles">
                      Anti Static Tiles
                    </a>
                  </li>
                  <li>
                    <a href="https://www.orientbell.com/tiles/carving-tiles">
                      Carving Tiles
                    </a>
                  </li>
                  <li>
                    <a href="https://www.orientbell.com/tiles/gvt-endless-tiles">
                      GVT Endless Tiles
                    </a>
                  </li>
                </ul>
              </div>
              <div className="foot-col">
                <div
                  className={
                    isClassToggled[4] ? "f-heading active-link" : "f-heading"
                  }
                  onClick={() => toggleClass(4)}
                >
                  <h6>Tiles by Colour</h6>
                </div>
                <ul>
                  <li>
                    <a href="https://www.orientbell.com/tiles/terracotta-tiles">
                      Terracotta Tiles
                    </a>
                  </li>
                  <li>
                    <a href="https://www.orientbell.com/tiles/white-tiles">
                      {" "}
                      White Tiles
                    </a>
                  </li>
                  <li>
                    <a href="https://www.orientbell.com/tiles/black-tiles">
                      Black Tiles
                    </a>
                  </li>
                  <li>
                    <a href="https://www.orientbell.com/tiles/grey-tiles">
                      Grey Tiles
                    </a>
                  </li>
                  <li>
                    <a href="https://www.orientbell.com/tiles/chequered-tiles">
                      {" "}
                      Chequered Tiles
                    </a>
                  </li>
                  <li>
                    <a href="https://www.orientbell.com/tiles/blue-tiles">
                      Blue Tiles
                    </a>
                  </li>
                  <li>
                    <a href="https://www.orientbell.com/tiles/red-tiles">
                      {" "}
                      Red Tiles
                    </a>
                  </li>
                  <li>
                    <a href="https://www.orientbell.com/tiles/green-tiles">
                      Green Tiles
                    </a>
                  </li>
                  <li>
                    <a href="https://www.orientbell.com/tiles/beige-tiles">
                      Beige Tiles
                    </a>
                  </li>
                  <li>
                    <a href="https://www.orientbell.com/tiles/ivory-tiles">
                      Ivory Tiles
                    </a>
                  </li>
                  <li>
                    <a href="https://www.orientbell.com/tiles/yellow-tiles">
                      {" "}
                      Yellow Tiles
                    </a>
                  </li>
                  <li>
                    <a href="https://www.orientbell.com/tiles/cream-tiles">
                      Cream Tiles
                    </a>
                  </li>
                  <li>
                    <a href="https://www.orientbell.com/tiles/pink-tiles">
                      Pink Tiles
                    </a>
                  </li>
                  {/* <li><a href="https://www.orientbell.com/tiles/orange-tiles">Orange Tiles</a></li> */}
                  <li>
                    <a href="https://www.orientbell.com/tiles/light-tiles">
                      Light Tiles
                    </a>
                  </li>
                  <li>
                    <a href="https://www.orientbell.com/tiles/dark-tiles">
                      Dark Tiles
                    </a>
                  </li>
                  <li>
                    <a href="https://www.orientbell.com/tiles/purple-tiles">
                      {" "}
                      Purple Tiles
                    </a>
                  </li>
                  <li>
                    <a href="https://www.orientbell.com/tiles/multi-colour-tiles">
                      {" "}
                      Multi Colour Tiles
                    </a>
                  </li>
                  <li>
                    <a href="https://www.orientbell.com/tiles/wenge-tiles">
                      {" "}
                      Wenge Tiles
                    </a>
                  </li>
                  <li>
                    <a href="https://www.orientbell.com/tiles/brown-tiles">
                      Brown Tiles
                    </a>
                  </li>
                  <li>
                    <a href="https://www.orientbell.com/tiles/sandune-tiles">
                      {" "}
                      Sandune Tiles
                    </a>
                  </li>
                  <li>
                    <a href="https://www.orientbell.com/tiles/black-and-white-tiles">
                      {" "}
                      Black and White Tiles
                    </a>
                  </li>
                </ul>
              </div>
              <div className="foot-col">
                <div
                  className={
                    isClassToggled[5] ? "f-heading active-link" : "f-heading"
                  }
                  onClick={() => toggleClass(5)}
                >
                  <h6>Tiles by Size</h6>
                </div>
                <ul>
                  <li>
                    <a href="https://www.orientbell.com/tiles/2x2-tiles">
                      600X600 mm Tiles
                    </a>
                  </li>
                  <li>
                    <a href="https://www.orientbell.com/tiles/2x4-tiles">
                      600X1200 mm Tiles
                    </a>
                  </li>
                  {/* <li><a href="https://www.orientbell.com/tiles/4x4-tiles">4x4 Tiles</a></li> */}
                  <li>
                    <a href="https://www.orientbell.com/tiles/1x1-tiles">
                      300X300 mm Tiles
                    </a>
                  </li>
                  {/* <li><a href="https://www.orientbell.com/tiles/1200x2400-tiles"> 4x8 mm Tiles</a></li> */}
                  <li>
                    <a href="https://www.orientbell.com/tiles/300x600-tiles">
                      300x600 mm Tiles
                    </a>
                  </li>
                  <li>
                    <a href="https://www.orientbell.com/tiles/395x395-tiles">
                      395x395 mm Tiles
                    </a>
                  </li>
                  <li>
                    <a href="https://www.orientbell.com/tiles/300x450-tiles">
                      300x450 mm Tiles
                    </a>
                  </li>
                  <li>
                    <a href="https://www.orientbell.com/tiles/200x1200-tiles">
                      200x1200 mm Tiles
                    </a>
                  </li>
                  <li>
                    <a href="https://www.orientbell.com/tiles/800x800-tiles">
                      800x800 mm Tiles
                    </a>
                  </li>
                  <li>
                    <a href="https://www.orientbell.com/tiles/inspire-xl-1200x1800">
                      800x1200 mm Tiles
                    </a>
                  </li>
                  <li>
                    <a href="https://www.orientbell.com/tiles/800x1600-tiles">
                      {" "}
                      800x1600 mm Tiles
                    </a>
                  </li>
                  <li>
                    <a href="https://www.orientbell.com/tiles/granalt-tiles">
                      {" "}
                      800x2400 mm Tiles
                    </a>
                  </li>
                  <li>
                    <a href="https://www.orientbell.com/tiles/145x600-tiles">
                      {" "}
                      145x600 mm Tiles
                    </a>
                  </li>
                  <li>
                    <a href="https://www.orientbell.com/tiles/195x1200-tiles">
                      {" "}
                      195x1200 mm Tiles
                    </a>
                  </li>
                  <li>
                    <a href="https://www.orientbell.com/tiles/295x295-tiles">
                      295x295 mm Tiles
                    </a>
                  </li>
                  <li>
                    <a href="https://www.orientbell.com/tiles/200x300-tiles">
                      {" "}
                      200x300 mm Tiles
                    </a>
                  </li>
                  <li>
                    <a href="https://www.orientbell.com/tiles/250x375-tiles">
                      {" "}
                      250x375 mm Tiles
                    </a>
                  </li>
                  <li>
                    <a href="https://www.orientbell.com/tiles/400x400-tiles">
                      {" "}
                      400x400 mm Tiles{" "}
                    </a>
                  </li>
                  {/* <li><a href="https://www.orientbell.com/tiles/148x600-tiles"> 148x600 mm Tiles</a></li> */}
                  <li>
                    <a href="https://www.orientbell.com/tiles/1000x1000-tiles">
                      1000x1000 mm Tiles
                    </a>
                  </li>
                </ul>
              </div>
              <div className="foot-col">
                <div
                  className={
                    isClassToggled[6] ? "f-heading active-link" : "f-heading"
                  }
                  onClick={() => toggleClass(6)}
                >
                  <h6>Tiles by Finish</h6>
                </div>
                <ul>
                  <li>
                    <a href="https://www.orientbell.com/tiles/matte-finish-tiles">
                      Matte Finish Tiles
                    </a>
                  </li>
                  <li>
                    <a href="https://www.orientbell.com/tiles/glossy-tiles">
                      Glossy Tiles
                    </a>
                  </li>
                  <li>
                    <a href="https://www.orientbell.com/tiles/sugar-finish-tiles">
                      Sugar Finish Tiles
                    </a>
                  </li>
                  {/* <li><a href="https://www.orientbell.com/tiles/satin-finish-tiles">Satin Finish Tiles</a></li> */}
                  <li>
                    <a href="https://www.orientbell.com/tiles/super-glossy-tiles">
                      Super Glossy Tiles
                    </a>
                  </li>
                  {/* <li><a href="https://www.orientbell.com/tiles/reactive-tiles">Reactive Tiles</a></li> */}
                  {/* <li><a href="https://www.orientbell.com/tiles/lapato-finish-tiles">Lapato Finish Tiles</a></li> */}
                </ul>
                <div className="foot-box">
                  <div
                    className={
                      isClassToggled[7] ? "f-heading active-link" : "f-heading"
                    }
                    onClick={() => toggleClass(7)}
                  >
                    <h6>Tiles Collection</h6>
                  </div>
                  <ul>
                    <li>
                      <a href="https://www.orientbell.com/tiles/tile-collection/sparkle">
                        Sparkle Tiles Collection
                      </a>
                    </li>
                    <li>
                      <a href="https://www.orientbell.com/tiles/tile-collection/estilo">
                        Estilo Tiles Collection
                      </a>
                    </li>
                    <li>
                      <a href="https://www.orientbell.com/tiles/tile-collection/ft-autumn2020">
                        FT Autumn 2020 Tiles Collection
                      </a>
                    </li>
                    {/* <li><a href="https://www.orientbell.com/tiles/tile-collection/zenith">Zenith Tiles Collection</a></li> */}
                    <li>
                      <a href="https://www.orientbell.com/tiles/tile-collection/sahara">
                        Sahara Tiles Collection
                      </a>
                    </li>
                    <li>
                      <a href="https://www.orientbell.com/tiles/tile-collection/rhino-series-pavers-tiles">
                        Rhino Tiles Collection
                      </a>
                    </li>
                    <li>
                      <a href="https://www.orientbell.com/tiles/tile-collection/duazzle">
                        Duazzle Tiles Collection
                      </a>
                    </li>
                    <li>
                      <a href="https://www.orientbell.com/tiles/tile-collection/inspire">
                        Inspire Tiles Collection
                      </a>
                    </li>
                    <li>
                      <a href="https://www.orientbell.com/tiles/tile-collection/hd-p-elevation">
                        HD-P Elevation Tiles Collection
                      </a>
                    </li>
                    <li>
                      <a href="https://www.orientbell.com/tiles/tile-collection/inspire-planks">
                        Inspire Planks Tiles Collection
                      </a>
                    </li>
                  </ul>
                </div>
                <div className="foot-box">
                  <div
                    className={
                      isClassToggled[15] ? "f-heading active-link" : "f-heading"
                    }
                    onClick={() => toggleClass(15)}
                  >
                    <h6>Tools</h6>
                  </div>
                  <ul>
                    <li>
                      <a href={`${config.HEADLESS_BASE_URL}/tile-calculator`}>
                        Tile Calculator
                      </a>
                    </li>
                    <li>
                      <a
                        href={`${config.HEADLESS_BASE_URL}/area-conversion-calculator`}
                      >
                        Area Calculator
                      </a>
                    </li>
                    <li>
                      <a
                        href={`${config.HEADLESS_BASE_URL}/paint-cost-calculator`}
                      >
                        Paint Calculator
                      </a>
                    </li>
                    <li>
                      <a
                        href={`${config.HEADLESS_BASE_URL}/construction-cost-calculator`}
                      >
                        Cost Estimator
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>

            <div className="footer-top ft-bottom flex-class">
              <div className="foot-col">
                <div
                  className={
                    isClassToggled[8] ? "f-heading active-link" : "f-heading"
                  }
                  onClick={() => toggleClass(8)}
                >
                  <h6>About Us</h6>
                </div>
                <ul>
                  <li>
                    <a href="https://www.orientbell.com/about-us">
                      Who are we?
                    </a>
                  </li>
                  <li>
                    <a href="https://www.orientbell.com/investor">
                      Investors Relation
                    </a>
                  </li>
                  <li>
                    <a href="https://www.orientbell.com/career">Careers</a>
                  </li>
                  <li>
                    <a href="https://www.orientbell.com/press-release">Media</a>
                  </li>
                  <li>
                    <a href="https://www.orientbell.com/sustainability">
                      Sustainability
                    </a>
                  </li>
                </ul>
              </div>
              <div className="foot-col">
                <div
                  className={
                    isClassToggled[9] ? "f-heading active-link" : "f-heading"
                  }
                  onClick={() => toggleClass(9)}
                >
                  <h6>Useful links</h6>
                </div>
                <ul className="smooth-check 12">
                  <li>
                    <a href="https://www.orientbell.com/contact-us">
                      Contact us
                    </a>
                  </li>
                  <li>
                    <a href="https://www.orientbell.com/faq">FAQ</a>
                  </li>
                  <li>
                    <a href="https://www.orientbell.com/store-locator">
                      Store Locator
                    </a>
                  </li>
                  <li>
                    <a href="https://www.orientbell.com/site-map">Sitemap</a>
                  </li>
                  <li>
                    <a href="https://www.orientbell.com/signature-company-showrooms">
                      Signature Company Showrooms
                    </a>
                  </li>
                  <li>
                    <a href="https://stores.orientbell.com">
                      Orientbell Tiles Boutique Locator
                    </a>
                  </li>
                </ul>
              </div>
              <div className="foot-col">
                <div
                  className={
                    isClassToggled[10] ? "f-heading active-link" : "f-heading"
                  }
                  onClick={() => toggleClass(10)}
                >
                  <h6>Solutions for</h6>
                </div>
                <ul>
                  <li>
                    <a href="https://www.orientbell.com/tile-selector">
                      Home Owners
                    </a>
                  </li>
                  <li>
                    <a href="https://www.orientbell.com/tiles/school-tiles">
                      Education
                    </a>
                  </li>
                  <li>
                    <a href="https://www.orientbell.com/trulook">
                      Home Designing Solution
                    </a>
                  </li>
                  <li>
                    <a href="https://www.orientbell.com/tile-buying">
                      Tile Buying Guide
                    </a>
                  </li>
                  <li>
                    <a href="https://www.orientbell.com/blog">
                      Inspirations for you
                    </a>
                  </li>
                  <li>
                    <a href="https://www.orientbell.com/blog/web-story/">
                      Web Stories
                    </a>
                  </li>
                  <li>
                    <a href="https://www.orientbell.com/design-ideas/">
                      Design Ideas
                    </a>
                  </li>
                </ul>
              </div>
              <div className="foot-col foot-connect">
                <div
                  className={
                    isClassToggled[12] ? "f-heading active-link" : "f-heading"
                  }
                  onClick={() => toggleClass(12)}
                >
                  <h6>Connect with us</h6>
                </div>
                <ul className="Social-Icons flex-class">
                  <li>
                    <a
                      href="https://www.facebook.com/OrientBellTiles/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <img
                        src="https://88nbxydt.cdn.imgeng.in/media/wysiwyg/facebook.jpg"
                        alt="Facebook"
                        loading="lazy"
                      />
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://www.linkedin.com/company/orientbellltd"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <img
                        src="https://88nbxydt.cdn.imgeng.in/media/wysiwyg/linkedIn.jpg"
                        alt="LinkedIn"
                        loading="lazy"
                      />
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://twitter.com/OrientbellTiles"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <img
                        style={{ background: "#e6e6e6" }}
                        width="20px"
                        src="https://www.edigitalagency.com.au/wp-content/uploads/new-Twitter-logo-x-black-png.png"
                        alt="Twitter"
                        loading="lazy"
                      />
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://wa.me/918750733333?text="
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <img
                        src="https://88nbxydt.cdn.imgeng.in/media/wysiwyg/whatsapp.jpg"
                        alt="WhatsApp"
                        loading="lazy"
                      />
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://www.instagram.com/orientbell/?hl=en"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <img
                        src="https://88nbxydt.cdn.imgeng.in/media/wysiwyg/insta-icon.png"
                        alt="instagram"
                        loading="lazy"
                      />
                    </a>
                  </li>
                </ul>
                <div className="select-lang">
                  <label htmlFor="P9LngDdl" style={{ color: "#fffff1" }}>
                    Change Language
                  </label>
                  <select
                    id="P9LngDdl"
                    className="mobilelanguga"
                    onChange={(e) => {
                      if (e.target.value) {
                        router.push(e.target.value);
                      }
                    }}
                  >
                    <option value="">Select </option>
                    <option value={`https://orientbell.com/${router.asPath}`}>
                      English
                    </option>
                    <option
                      value={`https://hindi.orientbell.com/${router.asPath}`}
                    >
                      हिंदी
                    </option>
                    <option
                      value={`https://tamil.orientbell.com/${router.asPath}`}
                    >
                      தமிழ்
                    </option>
                  </select>
                </div>
              </div>
              <div className="foot-col cpaddress">
                <div
                  className={
                    isClassToggled[13] ? "f-heading active-link" : "f-heading"
                  }
                  onClick={() => toggleClass(13)}
                >
                  <h6>Corporate Office</h6>
                </div>
                <p>
                  Iris House, 16 Business Centre,Nangal Raya,
                  <br />
                  DDA Complex,New Delhi – 110046 (India)
                </p>
                <div className="telno">
                  Tel: <a href="tel:911147119100">+91-11-47119100</a>, -{" "}
                  <a href="tel:911147119200">47119200</a>
                </div>
                <div className="telno">
                  Email:{" "}
                  <a href="mailto:customercare@orientbell.com">
                    customercare@orientbell.com
                  </a>
                </div>
              </div>
              <div className="foot-col">
                <div
                  className={
                    isClassToggled[14] ? "f-heading active-link" : "f-heading"
                  }
                  onClick={() => toggleClass(14)}
                >
                  <h6>Policies</h6>
                </div>
                <ul>
                  <li>
                    <a href="https://www.orientbell.com/privacy-policy">
                      Privacy Policy
                    </a>
                  </li>
                  <li>
                    <a href="https://www.orientbell.com/shipping-policy">
                      Shipping Policy
                    </a>
                  </li>
                  <li>
                    <a href="https://www.orientbell.com/return-and-refund-policy">
                      Returns &amp; Refund Policy
                    </a>
                  </li>
                </ul>

                <div className="maplocate">
                  <img
                    loading="lazy"
                    src="/footer-paymet2_1_1.webp"
                    alt="Image"
                  />
                </div>
                <div className="footer-badge-row">
                  <ul className="flex-class">
                    <li>
                      {" "}
                      <a
                        href="https://www.orientbell.com/media/pdf/GRIHA.pdf"
                        download
                      >
                        <img loading="lazy" src="/GRIHA.png" alt="Image" />
                      </a>
                    </li>
                    <li>
                      <a href="https://www.orientbell.com/quality-certificates">
                        <img loading="lazy" src="/CE-11.png" alt="Image" />
                      </a>
                    </li>
                    <li>
                      <a href="https://www.orientbell.com/quality-certificates">
                        <img loading="lazy" src="/IS.png" alt="Image" />
                      </a>
                    </li>
                    <li>
                      {" "}
                      <a
                        href="https://www.orientbell.com/media/pdf/Orient_Bell_Ltd.pdf"
                        download
                      >
                        <img loading="lazy" src="/IGBC.png" alt="Image" />
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        {tarrekImage && (
          <div class="get-in-touchpopup">
            <img
              // src="/Get in Touch Design for website 212x340 Pix.webp"
              src="/tareek_get_in_touch.avif"
              alt="tareek image"
              onClick={(e) => {
                e.stopPropagation();
                setGetInTouch(true);
                settarrekImage(false);
              }}
            />
            <div
              class="icon-close-black-icon"
              onClick={(e) => {
                e.stopPropagation();
                settarrekImage(false);
                localStorage.setItem("TarikClosed", true);
              }}
            >
              <img src="/close_round_icon.svg" alt="close icon" />
            </div>
          </div>
        )}
      </footer>
      <div className="copy-right">
        Copyright © 2025 OrientBell, All rights reserved.
      </div>
      <Modal
        show={getInTouch}
        className="pincode-popup-bx expert_assistance_modal ask-question-popup"
        onHide={() => {
          setTimePopup(false);
          setGetInTouch(false);
          localStorage.setItem("leadSkiped", true);
        }}
      >
        <Modal.Dialog>
          <Modal.Header closeButton>
            <Modal.Title>
              {TimePopup
                ? "Get in touch with our tile's expert"
                : "Get In Touch"}
            </Modal.Title>
          </Modal.Header>
          <div className="ask-question-section">
            <div className="expert-fig-bx">
              <img src="/new-tareek.jpg" />
            </div>
            <Modal.Body>
              <h2>Ask Tile Expert</h2>
              <p>Let Experts Help You In Buying Process</p>
              <Form
                onSubmit={(e) => {
                  e.preventDefault();
                  submitGetInTouch(
                    TimePopup
                      ? router.asPath.includes("/exports-project")
                        ? "Get In Touch 15_sec_Pop-up export"
                        : "Get In Touch 15_sec_Pop-up"
                      : router.asPath.includes("/exports-project")
                      ? "Get In Touch Tariq export"
                      : "Get In Touch Tariq"
                  );
                }}
              >
                <Form.Group className="mb-3" controlId="formBasicEmail">
                  <Form.Label>Name</Form.Label>
                  <Form.Control
                    type="text"
                    onChange={(e) => {
                      setTouch({
                        ...touch,
                        name: e.target.value,
                      });
                    }}
                  />
                </Form.Group>

                <Form.Group className="mb-3" controlId="formBasicPassword">
                  <Form.Label>Mobile</Form.Label>
                  {router.asPath.includes("/exports-project") ||
                  router.asPath.includes("/download-export-catalogue") ? (
                    <div className="export-page-getInTouchTariq">
                      <Dropdown
                        onSelect={(selectedCountryCode) => {
                          const selectedCountry = countries.find(
                            (country) => country.code === selectedCountryCode
                          );
                          setTouch({
                            ...touch,
                            countryCode: selectedCountryCode,
                            cc: selectedCountry ? selectedCountry.cc : "", // Update cc based on selected country
                          });
                        }}
                      >
                        <Dropdown.Toggle
                          variant="secondary"
                          id="dropdown-basic"
                        >
                          {touch.countryCode && (
                            <div>
                              <ReactCountryFlag countryCode={touch.cc} svg />
                              {` (${touch.countryCode})`}
                            </div>
                          )}
                          {!touch.countryCode && "Country Code"}
                        </Dropdown.Toggle>

                        <Dropdown.Menu
                          style={{ maxHeight: "200px", overflowY: "auto" }}
                        >
                          {countries.map((country) => (
                            <Dropdown.Item
                              key={country.code}
                              eventKey={country.code}
                            >
                              <div>
                                <ReactCountryFlag
                                  countryCode={country.cc}
                                  svg
                                />
                                {`${country.name} (${country.code})`}
                              </div>
                            </Dropdown.Item>
                          ))}
                        </Dropdown.Menu>
                      </Dropdown>
                      <Form.Control
                        type="number"
                        onChange={(e) => {
                          setTouch({
                            ...touch,
                            number: `${touch.countryCode || ""}${
                              e.target.value
                            }`, // Append the country code
                          });
                        }}
                      />
                    </div>
                  ) : (
                    <Form.Control
                      type="number"
                      onChange={(e) => {
                        setTouch({
                          ...touch,
                          number: `${touch.countryCode || ""}${e.target.value}`, // Append the country code
                        });
                      }}
                    />
                  )}
                </Form.Group>

                {router.asPath.includes("/exports-project") ||
                router.asPath.includes("/download-export-catalogue") ? (
                  <Form.Group className="mb-3" controlId="formBasicPassword">
                    <Form.Control
                      type="email"
                      value={touch.email}
                      placeholder="Email"
                      onChange={(e) => {
                        setTouch({
                          ...touch,
                          email: e.target.value,
                        });
                      }}
                    />
                  </Form.Group>
                ) : (
                  <Form.Group className="mb-3" controlId="formBasicPassword">
                    <Form.Label>Pincode (Optional)</Form.Label>
                    <Form.Control
                      type="number"
                      value={touch.pincode}
                      onChange={(e) => {
                        setTouch({
                          ...touch,
                          pincode: e.target.value,
                        });
                      }}
                    />
                  </Form.Group>
                )}
                {touch.error && (
                  <p className="error text-danger">{touch.error}</p>
                )}
                <div className="recording">
                  <button
                    onClick={recording ? stopRecording : startRecording}
                    type="button"
                  >
                    <span> </span>
                    <span> </span>
                    <span> </span>
                    <span> </span>
                    {recording ? (
                      <>
                        <img src="/recording.gif" alt="mic-image" /> Stop
                        Recording
                      </>
                    ) : (
                      <>
                        {" "}
                        <img src="/nic-ing.png" alt="mic-image" /> Record your
                        Audio
                      </>
                    )}
                  </button>
                </div>
                {audioBlob && (
                  <div>
                    <audio controls src={URL.createObjectURL(audioBlob)} />
                    {/* <button onClick={uploadAudio}>Upload</button> */}
                  </div>
                )}
                <Button
                  variant="primary"
                  type="submit"
                  disabled={touch.loading}
                  style={{ width: "100%" }}
                >
                  {touch.loading ? "Submitting" : "Submit"}
                </Button>
              </Form>
            </Modal.Body>
          </div>
          {/* <Modal.Footer>
              <Button variant="primary" onClick={() => handlePinSave()}>
                Submit
              </Button>
            </Modal.Footer> */}
        </Modal.Dialog>
      </Modal>
      <Modal
        show={LocationPopop}
        className="pincode-popup-bx expert_assistance_modal"
        onHide={() => {
          cookie.set("skipLocation", true, {
            expires: 7,
            secure: true,
            sameSite: "Strict",
          });
          setleadpopop();
          setLocationPopop(false);
          popoptarek();
        }}
      >
        <div className="main-location-popup">
          <Modal.Dialog>
            <Modal.Header closeButton>
              <div class="location-pop-section">
                <div class="location-icon-bx">
                  <img src="/location-icon.png" alt="location icon"></img>
                </div>
                <div class="location-text-bx">
                  {" "}
                  <Modal.Title>
                    Allow Your Location to get best prices in your area
                  </Modal.Title>
                </div>
              </div>
            </Modal.Header>
            <Modal.Body>
              <AddressAutocomplete
                setLocationPopop={setLocationPopop}
                setleadpopop={setleadpopop}
                settarrekImage={settarrekImage}
              />
            </Modal.Body>
            {/* <Modal.Footer>
              <Button variant="primary" onClick={() => handlePinSave()}>
                Submit
              </Button>
            </Modal.Footer> */}
          </Modal.Dialog>
        </div>
      </Modal>
    </>
  );
};

export default Footer;
